
import {
  PropType,
  computed,
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
  toRaw,
  watch,
} from "vue";

import type { sfConfType } from "@/types/sfConfType";
import { VueRecaptcha } from "vue-recaptcha";
import { ExporterFactory } from "../services/exporterFactory";

interface DataToSendType {
  campaignId: string;
  user: {
    name: string;
    city: string;
    phone: string;
    email: string;
    message: string;
    agreeCb: string[];
  };
  filters: {
    creditValue: number;
    creditPeriod: number;
    creditPurpose: string;
    secureValue: number;
    creditClientAge: number;
    propertySort: string;
  };
  productItem: {
    id: number;
    title: string;
    bank: string;
  };
}

export default defineComponent({
  name: "ContactForm",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  props: {
    contactTitle: String,
    contactFormButton: String,
    dataToSend: Object as PropType<DataToSendType>,
    sfConfig: Object as PropType<sfConfType | null>,
  },
  emits: ["close-modal"],
  setup: function (props, ctx) {
    const sfConfig = ref(props.sfConfig);
    const name = ref("");
    const nameError = ref(false);
    const city = ref("");
    const cityError = ref(false);
    const phone = ref("");
    const phoneError = ref(false);
    const email = ref("");
    const emailError = ref(false);
    const message = ref("");
    const agree = ref(false);
    const agreeError = ref(false);
    const agreeCb = ref<string[]>([]);
    const sent = ref(false);
    const sendDisabled = ref(true);

    const instance = getCurrentInstance();
    const sanitize = instance?.appContext.config.globalProperties.$sanitize;

    const modalPicPath = computed(() => {
      if (
        typeof sfConfig.value !== "undefined" &&
        sfConfig.value !== null &&
        sfConfig.value.internalModalFormPic !== null
      ) {
        return sfConfig.value.internalModalFormPic;
      } else {
        return "";
      }
    });

    const isModalPicture = computed(() => {
      return sfConfig.value?.internalModalFormPic !== "" ? true : false;
    });

    const agreeToggle = () => {
      agreeError.value = false;
    };

    const modalButtonSendClass = reactive({
      "sf-modalButtonSendDisabled": sendDisabled,
    });

    const sendModalForm = (e: Event) => {
      e.preventDefault();

      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      const sendToken = sfConfig.value?.sendToken;
      const sendTokenType = sfConfig.value?.sendTokenType;
      const sendUrlPath = sfConfig.value?.sendUrlPath;

      if (
        sfConfig.value?.sendType === "internalSF" ||
        (sfConfig.value?.sendType === "internalForm" &&
          typeof sendUrlPath !== "undefined" &&
          sendUrlPath !== "" &&
          typeof sendTokenType !== "undefined" &&
          sendTokenType !== "")
      ) {
        let error = 0;

        const dataName = name.value.replace(
          /[^a-zA-Z0-9 -ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/gi,
          ""
        );

        if (dataName == "") {
          nameError.value = true;
          error++;
        }

        const dataCity = city.value.replace(
          /[^a-zA-Z0-9 -ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/gi,
          ""
        );
        if (dataCity == "") {
          cityError.value = true;
          error++;
        }

        const dataPhone = phone.value.replace(/[^0-9 -+]/gi, "");
        if (dataPhone == "") {
          phoneError.value = true;
          error++;
        }

        const dataEmail = email.value.replace(/[^a-zA-Z0-9 -.@]/gi, "");
        if (dataEmail == "" || !emailRegex.test(dataEmail)) {
          emailError.value = true;
          error++;
        }

        const dataMsg = message.value.replace(
          /[^a-zA-Z0-9 -@.,ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/gi,
          ""
        );

        if (contactCheckboxes.value.length == 0 && agree.value === false) {
          agreeError.value = true;
          error++;
        }

        if (error > 0) {
          return false;
        }

        if (props.dataToSend !== undefined) {
          const propsData: DataToSendType = props.dataToSend;
          propsData.user = {
            city: dataCity,
            email: dataEmail,
            message: dataMsg,
            name: dataName,
            phone: dataPhone,
            agreeCb: agreeCb.value,
          };

          const externalExport = ExporterFactory.createExporter(sfConfig.value);
          externalExport.addData(propsData);
          externalExport.send();

          sent.value = true;
        }
      }
    };

    const siteKey = computed(() => {
      if (
        typeof sfConfig.value !== "undefined" &&
        sfConfig.value !== null &&
        typeof sfConfig.value.reCaptcha !== "undefined" &&
        sfConfig.value.reCaptcha !== ""
      ) {
        return atob(sfConfig.value.reCaptcha);
      } else {
        return "6Le7_y4mAAAAABC2vktKoCIg7fsgRduxo63fj9iS";
      }
    });

    const firmId = computed(() => {
      if (
        typeof sfConfig.value !== "undefined" &&
        sfConfig.value !== null &&
        typeof sfConfig.value.firmId !== "undefined"
      ) {
        return sfConfig.value.firmId;
      } else {
        return null;
      }
    });

    const contactCheckboxes = computed(() => {
      if (
        typeof sfConfig.value !== "undefined" &&
        sfConfig.value !== null &&
        typeof sfConfig.value.contactAgree !== "undefined"
      ) {
        return sfConfig.value.contactAgree;
      } else {
        return [];
      }
    });

    if (contactCheckboxes.value.length > 0) {
      sendDisabled.value = false;
    }

    const handleError = () => {
      sendDisabled.value = true;
    };

    const handleSuccess = () => {
      sendDisabled.value = false;
    };

    const handleExpired = () => {
      sendDisabled.value = true;
    };

    const contactFormPolicy = computed(() => {
      return sanitize(sfConfig.value?.contactFormPolicy);
    });
    const contactFormInfo = computed(() => {
      return sanitize(sfConfig.value?.contactFormInfo);
    });

    watch(sent, (newSent) => {
      if (newSent == true) {
        setTimeout(function () {
          sent.value = false;
          ctx.emit("close-modal");
        }, 3000);
      }
    });

    return {
      name,
      nameError,
      city,
      cityError,
      phone,
      phoneError,
      email,
      emailError,
      message,
      agree,
      agreeError,
      agreeCb,
      agreeToggle,
      isModalPicture,
      modalPicPath,
      sendModalForm,
      sendDisabled,
      modalButtonSendClass,
      sent,
      siteKey,
      handleError,
      handleSuccess,
      handleExpired,
      contactFormPolicy,
      contactFormInfo,
      firmId,
      contactCheckboxes,
    };
  },
});
