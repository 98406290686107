import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sf-miniwidget" }
const _hoisted_2 = { class: "sf-filtersMiniContainer" }
const _hoisted_3 = { class: "sf-filterMini" }
const _hoisted_4 = { class: "sf-filterMini" }
const _hoisted_5 = { class: "sf-filterMiniSlider" }
const _hoisted_6 = { class: "sf-filterMiniSlider" }
const _hoisted_7 = { class: "sf-offers" }
const _hoisted_8 = { class: "sf-minInstalment" }
const _hoisted_9 = { class: "sf-maxInstalment" }
const _hoisted_10 = { class: "sf-miniCheckBtnContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "sf-brand" }, "powered by Speedfin", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("p", null, "wartość nieruchomości:", -1)),
          _createElementVNode("h3", null, _toDisplayString(_ctx.showAsCurrency(_ctx.secureValueLocal, "PLN")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("p", null, "wkład własny:", -1)),
          _createElementVNode("h4", null, _toDisplayString(_ctx.ownContributionPercent) + " % = " + _toDisplayString(_ctx.showAsCurrency(_ctx.ownContributionValue, "PLN")), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Slider, {
              modelValue: _ctx.ownContributionPercent,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ownContributionPercent) = $event)),
              tooltips: false,
              min: 10,
              max: 70,
              step: 5,
              lazy: false,
              onChange: _ctx.ownContributionUpdate
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "okres kredytowania:", -1)),
          _createElementVNode("h4", null, _toDisplayString(_ctx.creditPeriod) + " lat", 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Slider, {
              modelValue: _ctx.creditPeriod,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.creditPeriod) = $event)),
              tooltips: false,
              min: 5,
              max: 35,
              step: 5,
              lazy: false,
              onChange: _ctx.creditPeriodUpdate
            }, null, 8, ["modelValue", "onChange"])
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["sf-summaryMiniContainer", { 'sf-loadingMini': _ctx.loading }])
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.statOffers), 1),
          _cache[6] || (_cache[6] = _createElementVNode("p", null, [
            _createTextVNode("ilość"),
            _createElementVNode("br"),
            _createTextVNode("ofert")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.showAsCurrency(_ctx.statMinInstallment, "PLN")), 1),
          _cache[7] || (_cache[7] = _createElementVNode("p", null, [
            _createTextVNode("minimalna"),
            _createElementVNode("br"),
            _createTextVNode("rata")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.showAsCurrency(_ctx.statMaxInstallment, "PLN")), 1),
          _cache[8] || (_cache[8] = _createElementVNode("p", null, [
            _createTextVNode("maksymalna"),
            _createElementVNode("br"),
            _createTextVNode("rata")
          ], -1))
        ])
      ], 2),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("button", {
          class: "sf-miniCheckBtn",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.checkMortgageOffer && _ctx.checkMortgageOffer(...args)))
        }, _toDisplayString(_ctx.filterButton), 1)
      ])
    ])
  ]))
}