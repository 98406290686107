import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sf-widget" }
const _hoisted_2 = { class: "sf-title" }
const _hoisted_3 = { class: "sf-filtersContainer" }
const _hoisted_4 = { class: "sf-f1" }
const _hoisted_5 = { class: "sf-f2" }
const _hoisted_6 = { class: "sf-f3" }
const _hoisted_7 = { class: "sf-f4" }
const _hoisted_8 = { class: "sf-f5" }
const _hoisted_9 = { class: "sf-f6" }
const _hoisted_10 = { class: "sf-mortgageCheckBtnContainer" }
const _hoisted_11 = {
  key: 0,
  class: "lds-facebook"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 0,
  class: "sf-resultArea"
}
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "sf-modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComboInputSlider = _resolveComponent("ComboInputSlider")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_ProductItem = _resolveComponent("ProductItem")!
  const _component_ContactForm = _resolveComponent("ContactForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createBlock(_component_ComboInputSlider, {
            inputValue: _ctx.creditVal,
            description: "KWOTA KREDYTU",
            minValue: 50000,
            maxValue: 2000000,
            step: 50000,
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedCreditVal
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createBlock(_component_ComboInputSlider, {
            inputValue: _ctx.periodVal,
            description: "OKRES KREDYTOWANIA",
            minValue: 1,
            maxValue: 35,
            step: 1,
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedPeriodVal
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_InputSelect, {
            optionsData: _ctx.creditPurposeOptions,
            default: 'zakup',
            description: "CEL KREDYTU",
            onChangedVal: _ctx.changedCreditPurposeVal,
            class: "creditPurpose"
          }, null, 8, ["optionsData", "onChangedVal"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(), _createBlock(_component_ComboInputSlider, {
            inputValue: _ctx.secureVal,
            description: "WARTOŚĆ NIERUCHOMOŚCI",
            minValue: 50000,
            maxValue: 2000000,
            step: 50000,
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedSecureVal
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(), _createBlock(_component_ComboInputSlider, {
            inputValue: _ctx.ageVal,
            description: "TWÓJ WIEK",
            minValue: 18,
            maxValue: 80,
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedAgeVal
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_InputSelect, {
            optionsData: _ctx.propertySortOptions,
            default: 'mieszkanie rynek wtórny',
            description: "RODZAJ ZABEZPIECZENIA",
            onChangedVal: _ctx.changedPropertySortVal,
            class: "propertySort"
          }, null, 8, ["optionsData", "onChangedVal"])
        ])
      ]),
      _createElementVNode("button", {
        class: "sf-mortgageCheckBtn",
        onClick: _cache[0] || (_cache[0] = (e) => _ctx.mortgageCheckClick(e))
      }, [
        _createElementVNode("div", _hoisted_10, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[1] || (_cache[1] = [
                _createElementVNode("div", null, null, -1),
                _createElementVNode("div", null, null, -1),
                _createElementVNode("div", null, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.filterButton), 1))
        ])
      ]),
      (_ctx.showResults)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resultList, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id
              }, [
                _createVNode(_component_ProductItem, {
                  item: item,
                  "result-button": _ctx.resultButton,
                  onShowContact: _ctx.showContact
                }, null, 8, ["item", "result-button", "onShowContact"])
              ]))
            }), 128)),
            (_ctx.resultInfo)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "sf-resultInfo",
                  innerHTML: _ctx.resultInfo
                }, null, 8, _hoisted_14))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.modal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_ContactForm, {
            "contact-title": _ctx.contactTitle,
            "contact-form-button": _ctx.contactFormButton,
            dataToSend: _ctx.dataToSend,
            sfConfig: _ctx.sfConfig,
            onCloseModal: _ctx.closeContact
          }, null, 8, ["contact-title", "contact-form-button", "dataToSend", "sfConfig", "onCloseModal"])
        ]))
      : _createCommentVNode("", true)
  ]))
}